import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import VERIFY_EMAIL_MUTATION from '../mutations/verifyEmail';

export default function useUrlRedirect() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [message, setMessage] = useState();

  const fallback = (href, blank) => {
    // Open in same or different window the original URL
    if (blank) window.open(href, '_blank');
    else window.location = href;
  };

  const getMobileOperatingSystem = () => {
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  };

  const openApp = (path, callback, blank) => {
    try {
      if (getMobileOperatingSystem() === 'iOS') {
        document.location = 'yousoon:/' + path;
      } else if (getMobileOperatingSystem() === 'Android') {
        document.location = 'yousoon:/' + path;
      } else {
        callback();
      }
    } catch (err) {
      // Reload window to have correct document
      console.log(err);
      window.location.reload(true);

      fallback(href, blank);
    }
  };

  const onVerifyEmailComplete = () => {
    history.push('/');
    setMessage('emailVerified');
  };

  const onVerifyEmailError = (error) => {
    setMessage(error.message);
  };

  const [verifyEmail] = useMutation(VERIFY_EMAIL_MUTATION, {
    onCompleted: onVerifyEmailComplete,
    onError: onVerifyEmailError,
  });

  useEffect(() => {
    if (location && location.pathname) {
      if (location.pathname.includes('auth/verify-email')) {
        openApp(location.pathname, () => {
          setMessage('pleaseWait');
          const [email, code] = location.pathname.split('/').slice(-2);
          verifyEmail({ variables: { email, code } });
        });
      } else if (location.pathname.includes('auth/verify-magic-link')) {
        openApp(location.pathname, () => {
          setMessage('pleaseUsePhone');
        });
      }
    }
  }, [location]);

  return [message ? t('emailValidation.' + message) : ''];
}
