import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import client from './libs/apolloClient';
import Homepage from './pages/Homepage';
import './libs/i18n';
import './App.css';

Sentry.init({
  dsn: 'https://ae2e832e196245eea3d8927cfdf150eb@o354362.ingest.sentry.io/5551805',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Suspense fallback="loading">
          <Homepage />
        </Suspense>
      </header>
    </div>
  );
}

const WrappedApp = () => {
  return (
    <Router>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  );
};

export default WrappedApp;
